<template>
	<div class="main-contents gray-back mt-0" style="height : 100%;">
		<div class="terms_wrap">
			<div class="title_wrap">
				<h2 v-if="param.stipDivCd == '01'">
				이용약관 미리보기 
					<div class="close" @click="$emit('close')">
						<img src="/images/close.png" alt="닫기"/>
					</div>
				</h2>
				<h2 v-else>
				개인정보 처리방침 미리보기
					<div class="close" @click="$emit('close')">
						<img src="/images/close.png" alt="닫기"/>
					</div>
				</h2>
			</div>
			<div class="tab_wrap">
				<div class="tab_cont_group">
          			<div class="tab_cont active scroll-box">
						<div class="tab_cont_inner">
							<div v-html="param.stipCont"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  props:['param'],
	data() {
		return {
			// stipCont : this.param.stipCont || '',
		}
	},	
  
	methods: {		
	},

  beforeMount() {
	// document.querySelector('#adm-wrap').setAttribute("id", "wrap");
  },
  beforeCreate() {
	// console.log("cre");
	// console.log(document.querySelector('#adm-wrap'));
	document.querySelector('#adm-wrap').setAttribute("id", "wrap");
  },
  beforeDestroy() {
	// console.log("de");
	// console.log(document.querySelector('#wrap'));
	document.querySelector('#wrap').setAttribute("id", "adm-wrap");
  },
}
</script>

<style>
.close {
	float : right;
	cursor : pointer;
}
</style>